import { Claim } from 'src/app/modules/shared/service-proxies/service-proxies';

export class User {
    readonly id: number;
    readonly firstName: string;
    readonly lastName: string;
    readonly mfaSetupComplete: boolean;
    readonly permissions: UserPermission[];

    constructor(
        id: number,
        firstName: string,
        lastName: string,
        permissions: UserPermission[],
        mfaSetupComplete: boolean,
    ) {
        this.id = id;
        this.firstName = firstName;
        this.lastName = lastName;
        this.permissions = permissions;
        this.mfaSetupComplete = mfaSetupComplete;
    }

    static fromClaims(claims: Claim[]): User {
        const id = parseInt(
            claims.find((c) => c.type === ClaimType.id)?.value ?? '0',
        );

        const firstName =
            claims.find((c) => c.type === ClaimType.firstName)?.value ?? '';

        const lastName =
            claims.find((c) => c.type === ClaimType.lastName)?.value ?? '';

        const permissions = claims
            .filter((c) => c.type === ClaimType.permission)
            .map((c) => c.value?.toLowerCase() as UserPermission);

        const amr =
            claims.find((c) => c.type === ClaimType.mfa_setup_status)?.value ??
            'pending';

        return new User(
            id,
            firstName,
            lastName,
            permissions,
            amr !== 'pending',
        );
    }

    get initials(): string {
        const initials =
            (this.firstName ? this.firstName.substring(0, 1) : '') +
            (this.lastName ? this.lastName.substring(0, 1) : '');

        return initials;
    }
}

enum ClaimType {
    amr = 'amr',
    email = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress',
    firstName = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/givenname',
    lastName = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/surname',
    id = 'http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier',
    permission = 'Permission',
    mfa_setup_status = 'mfa_setup_status',
}

export type UserPermission =
    | 'checklistmanagement'
    | 'commenting'
    | 'dashboard'
    | 'dealershipmanagement'
    | 'downloadvideo'
    | 'errors'
    | 'installer'
    | 'recording'
    | 'reporting'
    | 'userdeletion'
    | 'usermanagement'
    | 'vaultvideo'
    | 'viewdealershiprecording';
