import { Component, Input } from '@angular/core';
import {
    AdminChangePasswordCommand,
    ChangePasswordCommand,
    UsersServiceProxy,
} from '../../service-proxies/service-proxies';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { requireDigit } from '../../validators/requireDigit.validator';
import { requireUpperCase } from '../../validators/requireUppercase.validator';
import { requireNonAlphanumeric } from '../../validators/requireNonAlphanumeric.validator';
import { mustMatch } from '../../validators/mustMatch.validator';

interface PasswordResetForm {
    currentPassword: FormControl<string | null>;
    password: FormControl<string | null>;
    confirmPassword: FormControl<string | null>;
}

@Component({
    selector: 'app-change-password-modal',
    templateUrl: './change-password-modal.component.html',
})
export class ChangePasswordModalComponent {
    visible: boolean = false;

    @Input() currentPage!: 'profile' | 'admin';

    saving = false;

    formGroup = this.formBuilder.group<PasswordResetForm>(
        {
            currentPassword: new FormControl<string | null>(
                null,
                this.currentPage !== 'admin' ? Validators.required : null,
            ),
            password: new FormControl<string | null>(null, {
                validators: [
                    Validators.required,
                    Validators.minLength(6),
                    requireDigit(),
                    requireUpperCase(),
                    requireNonAlphanumeric(),
                ],
            }),
            confirmPassword: new FormControl<string | null>(null, {
                validators: [Validators.required],
            }),
        },
        {
            validators: mustMatch('password', 'confirmPassword'), // Apply the mustMatch validator to the form group
        },
    );
    private userId: number | undefined;

    get passwordControl(): FormControl<string | null> {
        return this.formGroup.controls.password;
    }

    get currentPasswordControl(): FormControl<string | null> {
        return this.formGroup.controls.currentPassword;
    }

    get confirmPasswordControl(): FormControl<string | null> {
        return this.formGroup.controls.confirmPassword;
    }

    constructor(
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private usersServiceProxy: UsersServiceProxy,
    ) { }

    public show(userId?: number): void {
        this.userId = userId;
        this.visible = true;
    }

    async changePassword(): Promise<void> {
        this.saving = true;
        try {
            if (this.currentPage === 'profile') {
                await firstValueFrom(
                    this.usersServiceProxy.changePassword(
                        new ChangePasswordCommand({
                            currentPassword: this.currentPasswordControl.value,
                            newPassword: this.passwordControl.value,
                        }),
                    ),
                );
            } else {
                if (this.userId !== undefined) {
                    await firstValueFrom(
                        this.usersServiceProxy.adminChangePassword(
                            new AdminChangePasswordCommand({
                                userId: this.userId,
                                newPassword: this.passwordControl.value,
                            }),
                        ),
                    );
                }
            }
            this.messageService.add({
                severity: 'success',
                summary: 'Success',
                detail: 'Password updated!',
            });

            this.visible = false;
        } finally {
            this.saving = false;
        }
    }

    close(): void {
        this.visible = false;
    }
}
