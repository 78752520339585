import { AbstractControl } from '@angular/forms';

export function requireNonAlphanumeric() {
    return (control: AbstractControl) => {
        return /^[0-9a-zA-Z]+$/.test(control.value)
            ? { requireNonAlphanumeric: true }
            : null;

        return null;
    };
}
