import { Injectable, OnDestroy } from '@angular/core';
import { DataStorageService, PendingUpload } from './data-storage.service';
import {
    BehaviorSubject,
    Subject,
    combineLatest,
    filter,
    firstValueFrom,
    interval,
    takeUntil,
    withLatestFrom,
} from 'rxjs';
import { CameraService } from './camera.service';
import { MediaSource } from '../models/media-source';
import { RubyApiProxyService } from 'src/app/core/services/ruby-api-proxy.service';
import {
    EventNames,
    TelemetryService,
} from 'src/app/core/services/telemetry.service';
import { DateTime, Duration } from 'luxon';
import { WindowsWorkerService } from './windows-worker.service';
import { LoggingService } from './logging.service';
import { AwsS3Service } from './aws-s3.service';
import { FeatureFlagsService } from 'src/app/core/services/feature-flags.service';
import {
    AbortRecordingReason,
    DealRecordingsServiceProxy,
    DealType,
    LogEntryType,
    SaleType,
    SelectedDeviceInfo,
    StartRecordingCommand,
    StopReason,
} from '../../shared/service-proxies/service-proxies';

@Injectable({
    providedIn: 'root',
})
export class RecordingService implements OnDestroy {
    public static readonly THUMBNAIL_EXTENSION = 'png';
    public static readonly TIMEOUT_EXPIRATION_MINUTES = 5;
    private static readonly TIMEOUT_EXTENSION_MINUTES = 15;
    private static readonly AUDIO_BITS_PER_SECOND = 128 * 1000; // 128 kbit/s
    private static readonly VIDEO_BITS_PER_SECOND = 1 * 1000 * 1000; // 1 Mbit/s
    private static readonly PREFERRED_MIME_TYPE = 'video/mp4;codecs:h264';
    private static readonly FALLBACK_MIME_TYPE = 'video/webm;codecs=vp8';

    private recorder: MediaRecorder | null = null;
    private startTimestamp: DateTime | null = null;
    private timeoutAcknowledgmentRequired = false;
    private timeoutExpiration: DateTime | null = null;
    private pendingUpload: PendingUpload | null = null;
    get currentPendingUpload(): PendingUpload | null {
        return this.pendingUpload;
    }

    private readonly elapsedDuration = new BehaviorSubject<number>(0);
    readonly elapsedDuration$ = this.elapsedDuration.asObservable();
    private readonly incompatibleBrowserDetected = new BehaviorSubject<boolean>(
        false,
    );
    readonly incompatibleBrowserDetected$ =
        this.incompatibleBrowserDetected.asObservable();
    private readonly isRecording = new BehaviorSubject<boolean>(false);
    readonly isRecording$ = this.isRecording.asObservable();
    private readonly recordingAttempted = new Subject<{
        dealRecordingId: number;
        reason: AbortRecordingReason;
    }>();
    readonly recordingAttempted$ = this.recordingAttempted.asObservable();
    private readonly recordingStarted = new Subject<PendingUpload>();
    readonly recordingStarted$ = this.recordingStarted.asObservable();
    private readonly recordingStopped = new Subject<PendingUpload>();
    readonly recordingStopped$ = this.recordingStopped.asObservable();
    private readonly timeoutExpired = new Subject<void>();
    readonly timeoutExpired$ = this.timeoutExpired.asObservable();

    private readonly unsubscribeSubject$ = new Subject<void>();

    constructor(
        private awsS3Service: AwsS3Service,
        private cameraService: CameraService,
        private dataStorageService: DataStorageService,
        private dealRecordingsServiceProxy: DealRecordingsServiceProxy,
        private featureFlagsService: FeatureFlagsService,
        private loggingService: LoggingService,
        private rubyApiProxyService: RubyApiProxyService,
        private telemetryService: TelemetryService,
        private windowsWorkerService: WindowsWorkerService,
    ) {
        this.cameraService.selectedMediaDevices$
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                withLatestFrom(this.isRecording$),
            )
            .subscribe(([devices, isRecording]) => {
                if (
                    !!devices.videoDevice &&
                    ((!devices.videoDevice.isIpCamera &&
                        !this.isUsbRecordingSupported()) ||
                        (devices.videoDevice.isIpCamera &&
                            !this.windowsWorkerService.isIpRecordingSupported()))
                ) {
                    this.incompatibleBrowserDetected.next(true);
                }

                if (isRecording) {
                    void this.stop(StopReason.DeviceUnplugged);
                }
            });

        combineLatest([this.isRecording$, interval(5000)])
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                filter(([isRecording]) => isRecording),
            )
            .subscribe(() => {
                if (this.pendingUpload) {
                    void this.dataStorageService.storePendingUpload(
                        this.pendingUpload,
                    );
                }
            });

        combineLatest([this.isRecording$, interval(500)])
            .pipe(
                takeUntil(this.unsubscribeSubject$),
                filter(([isRecording]) => isRecording),
            )
            .subscribe(() => {
                const duration =
                    this.startTimestamp?.diffNow() ?? Duration.fromMillis(0);
                const durationMilliseconds = Math.abs(
                    duration.as('milliseconds'),
                );
                if (this.pendingUpload) {
                    this.pendingUpload.duration = durationMilliseconds;
                }

                this.elapsedDuration.next(durationMilliseconds);
                if (
                    !this.timeoutExpiration ||
                    DateTime.local() < this.timeoutExpiration
                ) {
                    return;
                }

                if (!this.timeoutAcknowledgmentRequired) {
                    this.timeoutExpired.next();
                    this.timeoutExpiration = DateTime.local().plus({
                        minutes: RecordingService.TIMEOUT_EXPIRATION_MINUTES,
                    });
                    this.timeoutAcknowledgmentRequired = true;
                } else {
                    void this.stop(StopReason.Timeout);
                }
            });
    }

    ngOnDestroy(): void {
        this.unsubscribeSubject$.next(void 0);
        this.unsubscribeSubject$.complete();
    }

    acknowledgeTimeout(): void {
        this.timeoutExpiration = DateTime.local().plus({
            minutes: RecordingService.TIMEOUT_EXTENSION_MINUTES,
        });
        this.timeoutAcknowledgmentRequired = false;
    }

    async start(
        dealershipId: number,
        customerFirstName: string,
        customerLastName: string,
        stockNumber: string,
        dealNumber: string,
        saleType: SaleType,
        dealType: DealType,
        selectedAudioDevice: string | undefined,
        selectedVideoDevice: string | undefined,
        consentReceived: boolean,
        timeout: number,
    ): Promise<void> {
        const source = await firstValueFrom(this.cameraService.source$);
        const windowsServiceInfo = source?.isIpCameraStream
            ? await firstValueFrom(this.windowsWorkerService.serviceInfo$)
            : null;

        const command = StartRecordingCommand.fromJS({
            dealershipId,
            stockNumber,
            dealNumber,
            customerFirstName,
            customerLastName,
            saleType,
            dealType,
            selectedDeviceInfo: SelectedDeviceInfo.fromJS({
                audioDeviceName: selectedAudioDevice ?? 'N/A',
                videoDeviceName: selectedVideoDevice ?? 'N/A',
                isIpCameraRecording: source?.isIpCameraStream ?? false,
                windowsServiceVersion: windowsServiceInfo?.version,
                windowsServiceMachineName: windowsServiceInfo?.machineName,
            }),
        });

        if (!consentReceived) {
            command.abortRecordingReason = AbortRecordingReason.CustomerRefusal;
        } else if (!selectedVideoDevice || !selectedAudioDevice) {
            command.abortRecordingReason =
                AbortRecordingReason.DeviceUnavailable;
        } else if (!source) {
            command.abortRecordingReason = AbortRecordingReason.MissingStream;
        } else if (source.isMediaStream && !this.isUsbRecordingSupported()) {
            command.abortRecordingReason =
                AbortRecordingReason.UnsupportedMediaCodec;
        }

        let recordingId: number;
        if (this.featureFlagsService.useDotNetBackEnd) {
            const res = await firstValueFrom(
                this.dealRecordingsServiceProxy.start(command),
            );
            recordingId = res.id;
        } else {
            const res = await this.rubyApiProxyService.startRecording(command);
            recordingId = res.deal_transaction_id;

            if (source?.isIpCameraStream) {
                this.loggingService.log(
                    LogEntryType.MultipartUploadStarted, //using a bunk type since .NET has no equivalent log type and Ruby doesn't use this enum
                    `Windows service info: ${JSON.stringify(windowsServiceInfo)}`,
                    recordingId,
                );
            }
        }

        if (command.abortRecordingReason || !source) {
            this.recordingAttempted.next({
                dealRecordingId: recordingId,
                reason: command.abortRecordingReason,
            });
            return;
        }

        this.pendingUpload = await this.dataStorageService.createPendingUpload(
            recordingId,
            `${customerFirstName} ${customerLastName}`,
            source.isIpCameraStream,
            this.awsS3Service.getObjectKey(
                recordingId,
                this.getRecordingExtension(source),
            ),
            this.awsS3Service.getObjectKey(
                recordingId,
                RecordingService.THUMBNAIL_EXTENSION,
            ),
        );

        if (this.pendingUpload.isWindowsServiceRecording) {
            const selectedDevices = await firstValueFrom(
                this.cameraService.selectedMediaDevices$,
            );
            await this.windowsWorkerService.startRecording(
                recordingId,
                selectedDevices.audioDevice,
            );
        } else {
            this.startMediaRecorder(source.mediaStream);
        }

        this.startTimestamp = DateTime.local();
        this.timeoutExpiration = this.startTimestamp.plus({
            minutes: timeout,
        });
        this.recordingStarted.next(this.pendingUpload);
        this.isRecording.next(true);

        this.telemetryService.logEvent(EventNames.RecordingStarted, {
            dealRecordingId: recordingId,
            windowsServiceInfo,
        });
    }

    async addThumbnailImage(base64Image: string): Promise<void> {
        if (!this.currentPendingUpload) {
            return;
        }

        this.currentPendingUpload.videoThumbnail = base64Image;
        await this.dataStorageService.storePendingUpload(
            this.currentPendingUpload,
        );
    }

    async stop(reason: StopReason): Promise<void> {
        if (!this.pendingUpload) {
            return;
        }

        this.pendingUpload.stopReason = reason;
        await this.dataStorageService.storePendingUpload(this.pendingUpload);

        if (this.pendingUpload.isWindowsServiceRecording) {
            await this.windowsWorkerService.stopRecording();
        } else {
            this.stopMediaRecorder();
            await this.dataStorageService.storePendingUpload(
                this.pendingUpload,
            );
        }

        this.telemetryService.logEvent(EventNames.RecordingStopped, {
            dealRecordingId: this.pendingUpload.dealRecordingId,
            reason,
        });

        this.isRecording.next(false);
        this.recordingStopped.next(this.pendingUpload);
        this.startTimestamp = null;
        this.timeoutAcknowledgmentRequired = false;
        this.timeoutExpiration = null;
        this.pendingUpload = null;
    }

    private isUsbRecordingSupported(): boolean {
        return (
            MediaRecorder.isTypeSupported(
                RecordingService.PREFERRED_MIME_TYPE,
            ) ||
            MediaRecorder.isTypeSupported(RecordingService.FALLBACK_MIME_TYPE)
        );
    }

    private startMediaRecorder(stream: MediaStream): void {
        const recorder = new MediaRecorder(stream, {
            mimeType: this.getRecordingMimeType(),
            audioBitsPerSecond: RecordingService.AUDIO_BITS_PER_SECOND,
            videoBitsPerSecond: RecordingService.VIDEO_BITS_PER_SECOND,
        });
        recorder.ondataavailable = (e: BlobEvent): void => {
            if (e.data.size > 0) {
                this.pendingUpload?.pendingDataChunks.push(e.data);
            }
        };

        recorder.start(1000 / CameraService.FPS); // Must pass a number representing frame rate in ms
        this.recorder = recorder;
    }

    private stopMediaRecorder(): void {
        if (this.recorder?.state !== 'recording') {
            return;
        }

        this.recorder.stop();
    }

    private getRecordingExtension(source: MediaSource): string {
        if (source.isIpCameraStream) {
            return WindowsWorkerService.VIDEO_EXTENSION;
        }

        return this.getRecordingMimeType().split(';')[0].split('/')[1];
    }

    private getRecordingMimeType(): string {
        return MediaRecorder.isTypeSupported(
            RecordingService.PREFERRED_MIME_TYPE,
        )
            ? RecordingService.PREFERRED_MIME_TYPE
            : RecordingService.FALLBACK_MIME_TYPE;
    }
}
