import { Component, Input } from '@angular/core';
import {
    AdminChangeEmailRequestCommand,
    ProfileRequestEmailChangeCommand,
    UsersServiceProxy,
} from '../../service-proxies/service-proxies';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { mustMatch } from '../../validators/mustMatch.validator';
import { firstValueFrom } from 'rxjs';

interface EmailResetForm {
    emailOne: FormControl<string | null>;
    emailTwo: FormControl<string | null>;
}

@Component({
    selector: 'app-request-email-change-modal',
    templateUrl: './request-email-change-modal.component.html',
})
export class RequestEmailChangeModalComponent {
    @Input() currentPage!: 'profile' | 'admin';

    visible: boolean = false;
    saving = false;
    formGroup = this.formBuilder.group<EmailResetForm>(
        {
            emailOne: new FormControl<string | null>(null, {
                validators: [Validators.required, Validators.email],
            }),
            emailTwo: new FormControl<string | null>(null, {
                validators: [Validators.required, Validators.email],
            }),
        },
        { validators: [mustMatch('emailOne', 'emailTwo')] },
    );
    userId: number | undefined;

    constructor(
        private formBuilder: FormBuilder,
        private messageService: MessageService,
        private usersServiceProxy: UsersServiceProxy,
    ) {}

    public show(userId?: number): void {
        this.userId = userId;
        this.formGroup.reset();
        this.visible = true;
    }
    get emailOneControl(): FormControl<string | null> {
        return this.formGroup.controls.emailOne;
    }
    get emailTwoControl(): FormControl<string | null> {
        return this.formGroup.controls.emailTwo;
    }

    async sendRequest(): Promise<void> {
        if (this.formGroup.invalid) {
            this.formGroup.markAllAsTouched();
            return;
        }

        try {
            this.saving = true;

            if (this.currentPage === 'profile') {
                await firstValueFrom(
                    this.usersServiceProxy.requestEmailChange(
                        new ProfileRequestEmailChangeCommand({
                            email: this.emailOneControl.value,
                        }),
                    ),
                );
            } else {
                if (this.userId === undefined) {
                    return;
                }
                await firstValueFrom(
                    this.usersServiceProxy.requestUserEmailChange(
                        new AdminChangeEmailRequestCommand({
                            userId: this.userId,
                            email: this.emailOneControl.value,
                        }),
                    ),
                );
            }

            this.messageService.add({
                severity: 'success',
                summary: 'success',
                detail: 'Successfully requested email change',
            });
            this.visible = false;
        } finally {
            this.saving = false;
        }
    }

    close(): void {
        this.visible = false;
    }
}
