import { Injectable } from '@angular/core';
import { GoogleAnalyticsService } from '@hakimio/ngx-google-analytics';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

export class EventNames {
    static readonly PendingUploadDetected = 'PendingUploadDetected';
    static readonly RecordingStarted = 'RecordingStarted';
    static readonly RecordingStopped = 'RecordingStopped';
    static readonly StorageFull = 'StorageFull';
    static readonly StorageLow = 'StorageLow';
    static readonly WindowsServiceInstallationRequested =
        'WindowsServiceInstallationRequested';
    static readonly UploadAborted = 'UploadAborted';
    static readonly UploadFailed = 'UploadFailed';
    static readonly UploadSkipped = 'UploadSkipped';
    static readonly UploadSucceeded = 'UploadSucceeded';
}

@Injectable({ providedIn: 'root' })
export class TelemetryService {
    private appInsights?: ApplicationInsights;

    constructor(
        private authService: AuthService,
        private googleAnalyticsService: GoogleAnalyticsService,
    ) {
        if (environment.appInsights?.instrumentationKey) {
            this.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey:
                        environment.appInsights.instrumentationKey,
                    enableAutoRouteTracking: true,
                },
            });
            this.appInsights.loadAppInsights();
        }
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    logEvent(name: string, properties?: { [key: string]: any }): void {
        const customProperties = {
            userId: this.authService.user?.id ?? 0,
            ...properties,
        };
        this.appInsights?.trackEvent({ name }, customProperties);
        this.googleAnalyticsService.event(name, {
            options: customProperties,
        });
    }

    logException(exception: Error): void {
        this.appInsights?.trackException({ exception });
        this.googleAnalyticsService.exception(exception.message);
    }
}
