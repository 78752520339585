<p-dialog
    styleClass="modal-sm"
    [(visible)]="visible"
    [modal]="true"
    [draggable]="false"
    [closable]="false"
    xmlns="http://www.w3.org/1999/html">
    <ng-template pTemplate="header">
        <div>
            <h5 class="modal-title text-start">Change email</h5>
        </div>
    </ng-template>
    <div class="modal-body">
        <form [formGroup]="formGroup" (ngSubmit)="sendRequest()">
            <div class="row gy-3 mb-3">
                <div class="col-12">
                    <label for="emailOne" class="form-label"
                        >Enter a new email</label
                    >
                    <input
                        id="emailOne"
                        type="email"
                        pInputText
                        formControlName="emailOne"
                        class="form-control" />
                    <app-validation-message
                        [control]="emailOneControl"></app-validation-message>
                </div>
                <div class="col-12">
                    <label for="emailTwo" class="form-label"
                        >Confirm new email</label
                    >
                    <input
                        id="emailTwo"
                        type="email"
                        pInputText
                        formControlName="emailTwo"
                        class="form-control" />
                    <app-validation-message
                        [control]="emailTwoControl"></app-validation-message>
                </div>
            </div>
        </form>
    </div>

    <ng-template pTemplate="footer">
        <div class="text-end">
            <button (click)="close()" class="btn btn-secondary me-2">
                Cancel
            </button>
            <button
                type="button"
                (click)="sendRequest()"
                [disabled]="saving"
                class="btn btn-primary">
                Submit
            </button>
        </div>
    </ng-template>
</p-dialog>
