<p-dialog
    styleClass="modal-sm"
    [(visible)]="visible"
    [modal]="true"
    [draggable]="false"
    [closable]="false">
    <ng-template pTemplate="header">
        <div>
            <h4 class="modal-title text-start">Change Password</h4>
        </div>
    </ng-template>
    <div class="modal-body">
        <form [formGroup]="formGroup" (ngSubmit)="changePassword()">
            <div class="row gy-3 mb-3">
                <div class="col-12" *ngIf="this.currentPage === 'profile'">
                    <label for="currentPassword" class="form-label"
                        >Enter your current password</label
                    >
                    <p-password
                        id="currentPassword"
                        [feedback]="false"
                        [toggleMask]="true"
                        inputStyleClass="form-control"
                        formControlName="currentPassword"
                        autocomplete="password" />
                    <app-validation-message
                        [control]="
                            currentPasswordControl
                        "></app-validation-message>
                </div>
                <div class="col-12">
                    <label for="password" class="form-label"
                        >Enter a new password</label
                    >
                    <p-password
                        id="password"
                        [feedback]="false"
                        [toggleMask]="true"
                        inputStyleClass="form-control"
                        formControlName="password"
                        autocomplete="password" />
                    <app-validation-message
                        [control]="passwordControl"></app-validation-message>
                    <div
                        class="validation-message"
                        [class.disabled]="
                            !(passwordControl.touched || passwordControl.dirty)
                        "
                        [class.success]="!passwordControl.errors?.['required']">
                        This field is required.
                    </div>
                    <div
                        class="validation-message"
                        [class.disabled]="
                            !(passwordControl.touched || passwordControl.dirty)
                        "
                        [class.success]="
                            !passwordControl.errors?.['required'] &&
                            !passwordControl.errors?.['minlength']
                        ">
                        must be at least 6 characters.
                    </div>
                    <div
                        class="validation-message"
                        [class.disabled]="
                            !(passwordControl.touched || passwordControl.dirty)
                        "
                        [class.success]="
                            !passwordControl.errors?.['required'] &&
                            !passwordControl.errors?.['requireDigit']
                        ">
                        must have atleast one digit.
                    </div>
                    <div
                        class="validation-message"
                        [class.disabled]="
                            !(passwordControl.touched || passwordControl.dirty)
                        "
                        [class.success]="
                            !passwordControl.errors?.['required'] &&
                            !passwordControl.errors?.['requireUppercase']
                        ">
                        must have atleast one uppercase letter.
                    </div>
                    <div
                        class="validation-message"
                        [class.disabled]="
                            !(passwordControl.touched || passwordControl.dirty)
                        "
                        [class.success]="
                            !passwordControl.errors?.['required'] &&
                            !passwordControl.errors?.['requireNonAlphanumeric']
                        ">
                        must have atleast one non alphanumeric character.
                    </div>
                </div>

                <div class="col-12">
                    <label for="confirmPassword" class="form-label"
                        >Confirm new password</label
                    >
                    <p-password
                        id="confirmPassword"
                        [feedback]="false"
                        [toggleMask]="true"
                        inputStyleClass="form-control"
                        formControlName="confirmPassword"
                        autocomplete="none" />
                    <app-validation-message
                        [control]="confirmPasswordControl"
                        customMessage="Passwords must match"></app-validation-message>
                </div>
            </div>
        </form>
    </div>
    <ng-template pTemplate="footer">
        <div class="text-end">
            <button (click)="close()" class="btn btn-secondary me-2">
                Cancel
            </button>
            <button
                type="button"
                (click)="changePassword()"
                [disabled]="saving"
                class="btn btn-primary">
                Save changes
            </button>
        </div>
    </ng-template>
</p-dialog>
